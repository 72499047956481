import { stripRichTextWrapperTag, asText } from '../../utils/html';

export default (
  guidesSitemap,
  markets,
  guides,
  { $enhancedLinkSerializer }
) => {
  const guidesByMarket = [];

  markets
    ?.map((market) => market?.data?.title)
    ?.forEach((market) => {
      guidesByMarket.push(
        guides
          .filter((guide) => guide?.data?.market?.data?.title === market)
          .map((guide) => ({
            label: asText(guide.data.title),
            cta: $enhancedLinkSerializer({ link_type: 'Document', ...guide }),
          }))
      );
    });

  const sitemapItems = markets.map((_market, index) => {
    const { label, mainGuide } = guidesSitemap?.group_links[index];
    return {
      title: {
        label,
        ...(mainGuide && {
          cta: $enhancedLinkSerializer({
            link_type: 'Document',
            ...mainGuide,
          }),
        }),
      },
      items: guidesByMarket[index],
    };
  });

  return {
    title: stripRichTextWrapperTag(guidesSitemap.sitemap_title),
    items: sitemapItems,
  };
};
